import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';

import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  NamedLink,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import facebookImage from '../../assets/sneakertimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/sneakertimeTwitter-600x314.jpg';

import SectionHero from './SectionHero/SectionHero';
import SectionHowItWorks from './SectionHowItWorks/SectionHowItWorks';
import SectionFilteredSearches from './SectionFilteredSearches/SectionFilteredSearches';
import css from './LandingPage.module.css';
import SectionWhy from './SectionWhy/SectionWhy';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import SectionFilters from './SectionFilters';
import SectionFeaturedListing from './SectionFeaturedListing/SectionFeaturedListing';
import SectionHappySewer from './SectionHappySewer/SectionHappySewer';
import axios from 'axios';
export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    products,
    productsLoading,
    productsError,
    onManageDisableScrolling,
  } = props;
  const addressRedirectState = location?.state?.notAddress;
  const [showModal, setshowModal] = useState(false);
  useEffect(() => {
    if (addressRedirectState == true) {
      setshowModal(true);
    }
  }, [addressRedirectState === true]);
  // useEffect(() => {
  //   const id = 373193;
  //   console.log('LandingPageComponent');
  //   axios.get(`/fetchShippingMethod?id=${id}`).then(response => {
  //     console.log('hi', response.data);
  //   });
  // }, []);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  // console.log('schemaTitle', schemaTitle);
  // console.log('schemaDescription', schemaDescription);
  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <div className={css.filters}>
              <SectionFilters />
            </div>
            <SectionHero
              rootClassName={css.heroRoot}
              className={css.hero}
              history={history}
              location={location}
            />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionFilteredSearches />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHappySewer />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionFeaturedListing
                  loading={productsLoading}
                  error={productsError}
                  products={products}
                  isMapVariant={false}
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionWhy />
              </div>
            </li>
          </ul>
          <Modal
            id="addGenderInFormation"
            containerClassName={css.modalContainer}
            isOpen={showModal}
            onClose={() => setshowModal(false)}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div>
              <h2>{intl.formatMessage({ id: 'LandingPage.LandingPage.modalTitle' })}</h2>
              <p>{intl.formatMessage({ id: 'LandingPage.LandingPage.modalSubtitle' })}</p>
              <NamedLink name="ProfileSettingsPage" className={css.button}>
                {intl.formatMessage({ id: 'LandingPage.LandingPage.buttonText' })}
              </NamedLink>
            </div>
          </Modal>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;
  const { productIds, productsLoading, productsError } = state.LandingPage;
  const products = productIds?.map(t => {
    const ref = { id: t, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  });
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    products,
    productsLoading,
    productsError,
  };
};
const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});
// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
