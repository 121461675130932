import React from 'react';
import css from './SectionFeaturedListing.module.css';
import ProductCard from './ProductCard';
import { injectIntl } from '../../../util/reactIntl';
import { IconSpinner, CustomLoading, ListingCard } from '../../../components';
import classNames from 'classnames';

function SectionFeaturedListing({ loading, error, products, intl, isMapVariant }) {
  if (error) {
    return (
      <div>
        <span className={css.error}>
          {intl.formatMessage({ id: 'LandingPage.SectionFeaturedListingserror' })}
        </span>
      </div>
    );
  }
  if (loading) {
    return (
      <div>
        <p>
          <CustomLoading style={{ style: { height: '50px', width: '50px' } }} />
        </p>
      </div>
    );
  }
  // console.log('products', products);
  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };
  return (
    <div className={classNames(css.productsWrapper)}>
      {products?.length > 0 ? (
        <h3 className={`text-left text-3xl mt-6 ${css.allColumns}`}>
          {intl.formatMessage({ id: 'LandingPage.featuredListingTitle' })}
        </h3>
      ) : null}
      <div className={css.container}>
        {(products ?? []).map(p => (
          <ListingCard
            key={p.id.uuid}
            listing={p}
            renderSizes={cardRenderSizes(isMapVariant)}
            // setActiveListing={setActiveListing}
            // id={p.id.uuid}
            // key={p.id.uuid}
            // metadata={p?.attributes?.metadata}
            // title={p?.attributes?.title}
            // description={p?.attributes?.description}
            // publicData={p?.attributes?.publicData}
            // images={p?.images}
            // createdAt={p?.attributes?.createdAt}
            // intl={intl}
            // price={p?.attributes?.price}
            // authorName={p?.author?.attributes?.profile?.displayName}
          />
        ))}
      </div>
    </div>
  );
}

export default injectIntl(SectionFeaturedListing);
