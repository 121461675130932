import React, { useState } from 'react';
import css from './SectionHappySewer.module.css';
import Collapsible from 'react-collapsible';
import buyImage from './images/buy.png';
import sellImage from './images/sell.png';
import downIcon from './images/down.png';
import arrow from './images/arrow.png';
import { useIntl } from 'react-intl';
import { NamedLink } from '../../../components';
const SectionHappySewer = () => {
  const [show, setShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const intl = useIntl();
  return (
    <div className={css.container}>
      <div className={css.left}>
        <p className={css.plainText}>
          {intl.formatMessage({ id: 'SectionHappySewer.plainText1' })}
        </p>
        <p className={css.plainText}>
          {intl.formatMessage({ id: 'SectionHappySewer.plainText2' })}
        </p>
        <p className={css.highLightText}>
          {intl.formatMessage({ id: 'SectionHappySewer.highLightText' })}
        </p>
      </div>
      <div className={css.right}>
        <Collapsible
          trigger={
            <label htmlFor="sell" className={css.trigger} onClick={() => setShow(!show)}>
              <div>
                <img src={sellImage} alt="buy" className={css.image} />
              </div>
              <h2 className={css.triggerText}>
                {intl.formatMessage({ id: 'SectionHappySewer.sell' })}
              </h2>
              <div>
                <img src={show ? downIcon : arrow} className={css.downIcon} />
              </div>
            </label>
          }
          transitionTime={300}
        >
          <p className={css.paragraphBold}>
            <b>{intl.formatMessage({ id: 'SectionHappySewer.paragraphText1' })}</b>
          </p>
          <p className={css.paragraphText}>
            {intl.formatMessage({ id: 'SectionHappySewar.paragraphText2' })}
          </p>
          <p className={css.paragraphBold}>
            <b>{intl.formatMessage({ id: 'SectionHappySewar.paragraphText3' })}</b>
          </p>
          <p className={css.paragraphText}>
            {intl.formatMessage({ id: 'SectionHappySewar.paragraphText4' })}
          </p>
          <p className={css.paragraphText}>
            {intl.formatMessage({ id: 'SectionHappySewar.paragraphText5' })}
            <b> {intl.formatMessage({ id: 'SectionHappySewar.paragraphText6' })}</b>
          </p>
          <div className={css.buttonContainer}>
            <NamedLink name="CategoryPage" className={css.heroButton}>
              {intl.formatMessage({ id: 'SectionWhy.button' })}
            </NamedLink>
          </div>
        </Collapsible>
        <br />
        <Collapsible
          trigger={
            <label htmlFor="buy" className={css.trigger} onClick={() => setShowSearch(!showSearch)}>
              <div>
                <img src={buyImage} alt="buy" className={css.image} />
              </div>
              <h2 className={css.triggerText}>
                {intl.formatMessage({ id: 'SectionHappySewer.buy' })}
              </h2>
              <img src={showSearch ? downIcon : arrow} className={css.downIcon} />
            </label>
          }
          transitionTime={300}
        >
          <p className={css.paragraphBold}>
            <b>{intl.formatMessage({ id: 'SectionHappySewar.paragraphText7' })}</b>{' '}
          </p>
          <p className={css.paragraphText}>
            {' '}
            {intl.formatMessage({ id: 'SectionHappySewar.paragraphText8' })}
          </p>{' '}
          <p className={css.paragraphText}>
            {intl.formatMessage({ id: 'SectionHappySewar.paragraphText9' })}
          </p>
        </Collapsible>
      </div>
    </div>
  );
};

export default SectionHappySewer;
