import React from 'react';
import css from './SectionFilters.module.css';
import Popup from 'reactjs-popup';
import { ExternalLink, NamedLink } from '../../components';
import { FormattedMessage, useIntl } from 'react-intl';
const PlatFormMenu = () => {
  const intl = useIntl();
  let cookieValue;
  if (document) {
    cookieValue =
      document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'french';
  }
  return (
    <div>
      <Popup
        key="aaaa"
        trigger={
          <p className={css.scrollableLink}>
            <FormattedMessage id="LandingPage.ourPlatForm" />
          </p>
        }
        position="bottom center"
        on={['hover', 'focus']}
      >
        <div className={css.platFormContainer}>
          <div className={css.left}>
            <p className={css.staticLink}>
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.discover' })}
            </p>
            <NamedLink className={css.platFormLink} name="AboutPage">
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.howItWorks' })}
            </NamedLink>
            <NamedLink className={css.platFormLink} name="FaqPage">
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.helpCenter' })}
            </NamedLink>
            <NamedLink className={css.platFormLink} name="ContactUsPage">
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.contactUs' })}
            </NamedLink>
          </div>
          <div className={css.right}>
            <p className={css.staticLink}>
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.policies' })}
            </p>
            <NamedLink className={css.platFormLink} name="TermsOfServicePage">
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.termsOfService' })}
            </NamedLink>
            <NamedLink className={css.platFormLink} name="MangopayPolicyPage">
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.mangoPay' })}
            </NamedLink>
            <NamedLink className={css.platFormLink} name="PrivacyPolicyPage">
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.privacyPolicies' })}
            </NamedLink>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default PlatFormMenu;
