import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NamedLink } from '../../components';
import { withViewport } from '../../util/contextHelpers';
import config from '../../config';
import css from './SectionFilters.module.css';
import { compose } from 'redux';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import PlatFormMenu from './PlatFormMenu';
const MAX_MOBILE_SCREEN_WIDTH = 768;

function SectionFilters({ viewport, intl }) {
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const navRef = useRef();

  const [scroll, setScroll] = useState(0);

  const history = useHistory();
  const [leftOffset, setLeftOffset] = useState(0);
  const [topOffset, setTopOffset] = useState(0);
  const [btnOffset, setBtnOffset] = useState(0);

  const handleMouseEnter = e => {
    e.stopPropagation();
    const { x, y, height } = e.target.getBoundingClientRect();
    setTopOffset(y + height);
    setBtnOffset(y - 1);
    setLeftOffset(x);
  };

  const handleClick = href => history.push(href);

  useEffect(() => {
    let listener;
    if (navRef.current) {
      listener = () => {
        setScroll(navRef.current.scrollLeft);
      };
      navRef.current.addEventListener('scroll', listener);
    }
    return () => {
      if (navRef.current) {
        navRef.current.removeEventListener('scroll', listener);
      }
    };
  }, [navRef.current]);

  if (isMobileLayout) return null;

  return (
    <nav className={css.secondary}>
      <ul className={css.navContainer} ref={navRef}>
        {config.custom.categories.map(d => {
          let eventHandlers;
          if (d.href) {
            eventHandlers = { onClick: () => handleClick(d.href) };
          } else {
            eventHandlers = {
              onMouseEnter: e => handleMouseEnter(e),
            };
          }
          return (
            <li key={d.index} className={css.scrollableLink} {...eventHandlers}>
              <span
                onClick={() =>
                  history.push({
                    pathname: '/s',
                    search: `?pub_category=${d.key}`,
                  })
                }
              >
                {/* {d.icon && <img src={d.icon} alt={d.label} className={css.menuIcon} />} */}
                {intl.formatMessage({ id: d.label })?.toLowerCase()}
              </span>

              {d.key && (
                <ul
                  className={css.scrollableLinkChildren}
                  style={{ left: leftOffset, top: topOffset - 10 }}
                >
                  {config.custom[`${d?.key}SubCategory`].map(c => (
                    <li key={JSON.stringify(c)} className={css.childLabel}>
                      <NamedLink
                        name="SearchPage"
                        to={{ search: `?pub_category=${d.key}&pub_subcategory=${c.key}` }}
                      >
                        {c.icon && <img src={c.icon} alt={c.label} className={css.menuIcon} />}
                        {intl.formatMessage({ id: c.label })?.toLowerCase()}
                      </NamedLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          );
        })}
        <li className={css.scrollableLink}>
          <NamedLink name="AboutPage" className={css.link}>
            <FormattedMessage id="LandingPage.aboutUs" />
          </NamedLink>
        </li>
        <li className={css.scrollableLink}>
          <div name="AboutPage" className={css.link}>
            <PlatFormMenu />
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default compose(
  injectIntl,
  withViewport
)(SectionFilters);
