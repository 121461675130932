import React from 'react';
import { useIntl } from 'react-intl';
import css from './SectionWhy.module.css';
import image1 from './Images/Bottom-1.png';
import image2 from './Images/Bottom-2.png';
import image3 from './Images/bottom-3.png';
import image4 from './Images/Bottom-4.png';
import { NamedLink } from '../../../components';
function SectionWhy() {
  const intl = useIntl();
  const images = [
    { image: image1, text: intl.formatMessage({ id: 'sectionWhyImage1text' }) },
    { image: image2, text: intl.formatMessage({ id: 'sectionWhyImage2text' }) },
    { image: image3, text: intl.formatMessage({ id: 'sectionWhyImage3text' }) },
    { image: image4, text: intl.formatMessage({ id: 'sectionWhyImage4text' }) },
  ];
  return (
    <div>
      <h3 className={css.headings}>
        {intl.formatMessage({
          id: 'SectionWhy.title',
        })}
      </h3>
      <div className={css.container}>
        {images.map((image, index) => (
          <div className={css.bodyContainer} key={index}>
            <img key={index} src={image.image} alt="Why" className={css.image} />
            <p className={css.text}>{image.text}</p>
          </div>
        ))}
      </div>
      <div className={css.buttonContainer}>
        <NamedLink name="CategoryPage" className={css.heroButton}>
          {intl.formatMessage({ id: 'SectionWhy.button' })}
        </NamedLink>
      </div>
    </div>
  );
}

export default SectionWhy;
